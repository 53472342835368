@font-face {
  font-family: 'LatoWeb';
  src: url('assets/fonts/Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
       url('assets/fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  font-family: 'LatoWeb', -apple-system, BlinkMacSystemFont, 'LatoWeb', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout {
  overflow-y: hidden;
  background: #fff !important;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.font-semi-bold {
  font-weight: 500;
}

.font-bold {
  font-weight: bold;
}

.font-italic {
  font-style: italic;
}

.ant-btn-primary {
  background-color: #C2FAE5!important;
  border-radius: 5px;
  padding: 5px 20px;
  color: #000000 !important;
  border-color: #C2FAE5 !important;
}

.kXoGKB.ant-menu-item-selected .anticon, .kXoGKB.ant-menu-item-selected a, .kXoGKB.ant-menu-item-selected svg {
  color: #000000 !important;
}
.ant-menu-item .anticon:hover, .ant-menu-item a, .ant-menu-item svg {
  color: #000000 !important;
}

.ant-picker-now-btn{
  color: #000 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before{
  border: 1px solid #C2FAE5 !important;
  color: #000 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  color: #000 !important;
  background: #C2FAE5 !important;
}
.ant-picker-month-btn:hover{
  color:#000 !important; 
}
.ant-picker-year-btn:hover{
  color:#000 !important; 
}
.ant-picker-decade-btn:hover{
  color:#000 !important; 
}



/* canvas {
  width: 100% !important;
} */